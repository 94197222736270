import {
	Component,
	OnDestroy,
	OnInit,
	Signal,
	inject,
	signal,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '../environments/environment.development'
import { Subject, Subscription, interval, takeUntil } from 'rxjs'
import { PrimeNGConfig } from 'primeng/api'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { EnvironmentService } from '../environments/environment.service'
import { ApiService } from './core/backoffice/services/api.service'
import newNotification from './core/home/components/navbar/navbar.component'
import { UserPermissionConfig } from './core/authorization/roles/roles-config'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy, OnInit {
	loading: Signal<boolean>
	private destroy$ = new Subject<void>()
	//subscription: Subscription;
	constructor(
		public translate: TranslateService,
		public primeNGConfig: PrimeNGConfig,
		private router: Router,
		private titleService: Title,
		private envSer: EnvironmentService,
		public envService: EnvironmentService,
		private apiService: ApiService,
	) {
		this.loading = isLoading
		this.translate.addLangs(['en-UK', 'pt-PT'])
		this.translate.setDefaultLang(environment.defaultLanguage)
		this.translate.onLangChange
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.translate
					.stream('primeng')
					.pipe(takeUntil(this.destroy$))
					.subscribe((res) => {
						this.primeNGConfig.setTranslation(res)
					})
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	checkForNewNotifications() {
		if (this.envSer.getUserId() === undefined) return
		this.apiService
			.getAllNotifications(
				this.envSer.getUserId()!,
				0,
				100,
				'',
				'',
				false,
			)
			.subscribe((res) => {
				if (res.count > 0) newNotification.set(true)
				res.items.forEach((notification) => {
					if (notification.notificationType === 'Role updated') {
						// toast

						this.envSer.logout()
					}
				})
			})
	}

	ngOnInit(): void {
		if (
			this.envService.checkUserContainsAllPermissions([
				UserPermissionConfig.EditOwnAccount,
			])
		) {
			this.checkForNewNotifications()
			interval(environment.notificationsInterval)
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					this.checkForNewNotifications()
				})
		}

		//change the title of the page based on the route
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			//get the name from the translation file
			const sub = this.translate.get('pagesTitles').subscribe((res) => {
				let subUrl = this.router.url
					.split('/')
					.slice(-1)[0]
					.split('?')[0]
					.split('%3F')[0]
					.split('#')[0]

				if (this.router.url.includes('monitoring')) {
					subUrl = 'monitoring'
				}

				switch (subUrl) {
					case '':
						this.titleService.setTitle(res.home)
						break
					case 'refresh':
						break
					default:
						this.titleService.setTitle(res[subUrl] || res.notfound)
						break
				}
			})
			sub.unsubscribe()
		})

		//check if the language is already set
		if (localStorage.getItem('language') != null) {
			this.translate.setDefaultLang(localStorage.getItem('language')!)
			this.translate.use(localStorage.getItem('language')!)
			return
		}
		var browserDefaultLang = this.translate.getBrowserLang()
		if (browserDefaultLang == 'pt') {
			this.translate.setDefaultLang('pt-PT')
			this.translate.use('pt-PT')
		} else if (browserDefaultLang == 'en') {
			this.translate.setDefaultLang('en-UK')
			this.translate.use('en-UK')
		} else {
			this.translate.setDefaultLang(environment.defaultLanguage)
			this.translate.use(environment.defaultLanguage)
		}
	}
}
export var isLoading = signal(false)
