export const environment = {
	production: true,
	hmr: false,
	serviceworker: true,
	serverUrl: 'https://transporte-flexivel-cmcb-server.latitudde.com',
	mock: false,
	aesKey: '7567804B73A862C8D314C1A6DCF0B680',
	aesEncrypt: false,
	apiVersion: 'v1',
	geocodingApiKey: '661c407bb4d55621535896usfb96dc3',
	defaultLanguage: 'pt-PT',
	shortToast: 4000,
	longToast: 7000,
	minQueryLength: 3,
	maxItemsLoad: 100,
	popularRoutesLoad: 3,
	osrmServerUrl:
		'https://transporte-flexivel-cmcb-server.latitudde.com/osrm/api/',
	notificationsInterval: 30000,
	defaultTime: [6, 0, 0], //default time that appears when creating a request
}
