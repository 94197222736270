<head>
	<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/primeng/17.18.8/resources/primeng.min.css"
		integrity="sha512-tuDeX2aebP3BBr/1OdCHSUHcsi2WI9/+pmINxXD5V6y1dqIY+GJKsKfPaoVbtug7kudatIKVNB7Ghb6IanVnMw=="
		crossorigin="anonymous"
		referrerpolicy="no-referrer"
	/>
	<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/primeng/17.18.8/resources/themes/lara-light-blue/theme.css"
		integrity="sha512-tK29CU2oq2dum2XxxahEXMKvf5dQlGWaGSXovKthm3wmAdBcXLzcwnkRbloAF5LMc4qs2YL3RNwwr57urPtXJQ=="
		crossorigin="anonymous"
		referrerpolicy="no-referrer"
	/>
	<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/primeflex/3.3.1/primeflex.min.css"
		integrity="sha512-lgW20MsPeg254Pqm/Wfu3UrS0b5z5VoLcfgYthMePeoCUR8hgiswV9FllOYd3YZvckbjz+jnfiAMnXjSQmeB5w=="
		crossorigin="anonymous"
		referrerpolicy="no-referrer"
	/>
	<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/primeicons/6.0.1/primeicons.min.css"
		integrity="sha512-c0Y5PGgrf6YXC12zY4vBuSai7jo75U1vhowEhHTb6Hcn4PNv88Ip1fVFkFOhuXtb6qfFHbRvlfOznlgVTlVmUQ=="
		crossorigin="anonymous"
		referrerpolicy="no-referrer"
	/>
</head>
<div
	*ngIf="loading()"
	class="fixed w-full h-full progress-spinner-background"
	[style]="{ 'z-index': '5001' }"
>
	<p-progressSpinner
		mode="indeterminate"
		strokeWidth="4"
		[style]="{
			height: '99px',
			position: 'absolute',
			top: '50%',
			left: '50%',
			width: '100%',
			'z-index': '5002',
			transform: 'translate(-50%, -50%)',
		}"
		[hidden]="!loading()"
	>
	</p-progressSpinner>
</div>
<router-outlet></router-outlet>
<div class="w-8 div-master">
	<div class="absolute flex">
		<p-toast
			position="top-center"
			key="errorToast"
			[breakpoints]="{
				'768px': {
					width: 'calc(100% - 40px)',
					'max-width': '509px',
					margin: 'auto',
					top: '90px',
				},
			}"
		>
			<ng-template let-message pTemplate="message">
				<div class="expiredTokenToastIcon">
					<i
						class="expiredTokenIconSize pi pi-exclamation-circle"
					></i>
					<div class="marginLeft">
						{{ message.detail }}
					</div>
				</div>
			</ng-template>
		</p-toast>
	</div>

	<div class="toast-container">
		<div class="absolute flex">
			<p-toast
				position="top-center"
				key="successToast"
				[breakpoints]="{
					'768px': {
						width: 'calc(100% - 40px)',
						'max-width': '509px',
						margin: 'auto',
						top: '90px',
					},
				}"
			>
				<ng-template let-message pTemplate="message">
					<div
						class="expiredTokenToastIcon"
						[ngClass]="{
							'success-message': message.severity === 'success',
							'error-message': message.severity === 'error',
						}"
					>
						<i
							class="expiredTokenIconSize pi pi-exclamation-circle"
							[ngClass]="{
								'pi-exclamation-circle':
									message.severity === 'error' ||
									message.severity === 'success',
							}"
						></i>
						<div class="marginLeft">
							{{ message.detail }}
						</div>
					</div>
				</ng-template>
			</p-toast>
		</div>
	</div>

	<div class="toast-container">
		<div class="absolute flex">
			<p-toast
				position="top-center"
				key="infoToast"
				[breakpoints]="{
					'768px': {
						width: 'calc(100% - 40px)',
						'max-width': '509px',
						margin: 'auto',
						top: '90px',
					},
				}"
			>
				<ng-template let-message pTemplate="message">
					<div class="expiredTokenToastIcon info-message">
						<i class="expiredTokenIconSize pi pi-info-circle"></i>
						<div class="marginLeft">
							{{ message.detail }}
						</div>
					</div>
				</ng-template>
			</p-toast>
		</div>
	</div>

	<div class="absolute flex">
		<p-toast
			position="top-center"
			key="expiredToken"
			[breakpoints]="{
				'768px': {
					width: 'calc(100% - 40px)',
					'max-width': '509px',
					margin: 'auto',
					top: '90px',
				},
			}"
		>
			<ng-template let-message pTemplate="message">
				<div class="expiredTokenToastIcon">
					<i
						class="expiredTokenIconSize pi pi-exclamation-circle"
					></i>
					<div class="marginLeft">
						{{ message.summary }}
					</div>
				</div>
			</ng-template>
		</p-toast>
	</div>
</div>
